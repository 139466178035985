import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';

function AwardFeature() {
  return (
    <div className="flex justify-center	items-center relative bg-white border-b">
      <StaticImage src="../images/hi-tech.png" alt="Hi-Tech award winners" placeholder="blurred" width={640} height={148} className="z-10" />
      <div className="lg:bg-gradient-to-r from-red via-purple to-blue absolute h-full w-full opacity-60" />
    </div>
  );
}

export default AwardFeature;
