import React from 'react';
import {graphql} from 'gatsby';
import {motion} from 'framer-motion';
import Hero from 'components/Hero';
import {pageVariants, pageTransition} from 'components/Transitions';
import Layout from 'components/Layout';
import AwardFeature from 'components/AwardFeature';
import PageBuilder from "../components/page-builder/PageBuilder";

//Stylesheet
import 'styles/App.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader

const Home = ({data}) => {
  const [
    {
      seomatic,
      hero,
      showAwardsBanner,
      heroHeadingTopLevel,
      heroHeadingHighlight,
      heroHeadingBottomLevel,
      blocks,
    },
  ] = data.allCraftHomeHomeEntry.nodes;

  return (
    <Layout hasContact seomatic={seomatic}>
      <motion.div
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
        transition={pageTransition}
        className="bg-white home font-display"
      >
        <Hero
          headingTop={heroHeadingTopLevel}
          headingHighlight={heroHeadingHighlight}
          headingBottom={heroHeadingBottomLevel}
          buttonOne={hero[0]}
          buttonTwo={hero[1]}
        />
        {showAwardsBanner ? <div className="tracking-wide font-body wrap">
          <div className="pt-2 pb-8 pl-6 pr-6 lg:pt-0 lg:pl-0 lg:pr-0">
            <AwardFeature />
          </div>
        </div> : <div className="pb-8" />}

        <PageBuilder blocks={blocks} />

      </motion.div>
    </Layout>
  );
};

export const query = graphql`
  query HomeQuery {
    allCraftHomeHomeEntry {
      nodes {
        seomatic {
          ...SeomaticFields
        }
        showAwardsBanner
        heroHeadingTopLevel
        heroHeadingHighlight
        heroHeadingBottomLevel
        hero {
          ... on Craft_hero_heroFields_BlockType {
            headingTopLevel
            headingHighlight
            headingBottomLevel
            linkLabel
            entry {
              uri
            }
            icon {
              title
              ... on Craft_assets_Asset {
                id
                uri
                filename
                url
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 120, height: 120, quality: 100)
                  }
                }
              }
              img
            }
          }
        }

        # START OF PAGE BUILDER BLOCKS
        blocks {
          ...on Craft_blocks_article_BlockType {
            typeHandle
            heroBannerImage {
              ... on Craft_assets_Asset {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1024, height: 768, quality: 80)
                    id
                  }
                }
              }
            }
            workingWithUsHeading
            workingWithUsBody
          }
          ...on Craft_blocks_caseStudies_BlockType {
            typeHandle
            heading
            caseStudies {
              ...on Craft_caseStudies_BlockType {
                id
                heading
                highlightedHeading
                shortDescription
                readMoreLabel
                heroImage {
                  ... on Craft_assets_Asset {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 800, height: 600, quality: 80)
                      }
                    }
                  }
                }
                caseStudy {
                  ... on Craft_caseStudies_caseStudy_Entry {
                    uri
                    tags {
                      id
                      title
                    }
                  }
                }
              }
            }
          }
          ...on Craft_blocks_cta_BlockType {
            typeHandle
            backgroundColour
            callToActionButtonLabel
            callToActionMailTo
            callToActionEntry {
              uri
              title
            }
          }
          ...on Craft_blocks_fullWidthScreenshot_BlockType {
            typeHandle
            screenshot {
              ... on Craft_assets_Asset {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1280, height: 600, quality: 80, placeholder: DOMINANT_COLOR)
                  }
                }
              }
            }
            screenshotAlt
          }
          ...on Craft_blocks_keypoints_BlockType {
            typeHandle
            heading
            backgroundColour
            callToActionButtonLabel
            callToActionEntry {
              uri
            }
            callToActionMailTo
            keypoints {
              ...on Craft_keypoints_BlockType {
                id
                keypointTitle
                keypointContent
              }
            }
          }
          ...on Craft_blocks_largeVideo_BlockType {
            typeHandle
            heading
            description
            embedUrl
            ctaText
            callToActionUrl
            placeholderImage {
              url
            }
            placeholderImageAlt
          }
          ...on Craft_blocks_logos_BlockType {
            typeHandle
            heading
            backgroundColour
            logos {
              ...on Craft_logos_BlockType {
                id
                partnerName
                partnerLink
                logo {
                  ... on Craft_assets_Asset {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(height: 120, layout: CONSTRAINED)
                      }
                    }
                  }
                }
              }
            }
          }
          ...on Craft_blocks_process_BlockType {
            typeHandle
            heading
            process {
              ...on Craft_process_BlockType {
                id
                heading
                description
                image {
                  ... on Craft_assets_Asset {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(height: 60, layout: CONSTRAINED)
                      }
                    }
                  }
                }
                entry {
                  id
                  uri
                }
              }
            }
          }
          ...on Craft_blocks_quote_BlockType {
            typeHandle
            speechMarksColour
            text
            caption
          }
          ...on Craft_blocks_resources_BlockType {
            typeHandle
            heading
            resources {
              ...on Craft_resources_BlockType {
                id
                heading
                shortDescription
                heroImage {
                  ... on Craft_assets_Asset {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(width: 460, height: 210, quality: 80)
                      }
                    }
                  }
                }
                resource {
                  id
                  uri
                }
              }
            }
          }
          ...on Craft_blocks_testimonials_BlockType {
            typeHandle
            heading
          }
          ...on Craft_blocks_textAndImageVideo_BlockType {
            typeHandle
            text
            heroImageAlt
            embedUrl
            backgroundColour
            heroImage {
              ... on Craft_assets_Asset {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 1024, height: 768, quality: 80)
                    id
                  }
                }
              }
            }
          }
          ...on Craft_blocks_whatWeDo_BlockType {
            typeHandle
            heading
            description
            backgroundColour
            underlineColour
            ourExpertise {
              ...on Craft_ourExpertise_BlockType {
                heading
                description
                entry {
                  id
                  uri
                }
              }
            }
          }
          ...on Craft_blocks_wysiwyg_BlockType {
            typeHandle
            wysiwygContent
            backgroundColour
          }
        }
        # END OF PAGE BUILDER BLOCKS

      }
    }
  }
`;

export default Home;
